/* owl slider button */
.owl-prev,
.owl-next {
    background-color:rgba(0,0,0,0.5);
    border-color: rgba(0,0,0,0.5);
    color: #ffffff;
    padding: 5px 8px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
    display: inline-block;
    cursor: pointer;
    border-width: 0px;
    border-style: solid;
}

.owl-prev:hover,
.owl-prev:active,
.owl-prev:focus,
.owl-next:hover,
.owl-next:active,
.owl-next:focus {
    background-color: rgba(0,0,0,0.7);
    border-color: #3E2E23;
    color: #ffffff;
}
.arrow-none .slick-arrow{
	display:none!important;
}
.owl-theme .owl-nav,
.owl-theme .owl-dots {
    margin-top: 20px;
    text-align: center;
}
.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}
/* owl dots button */
.owl-theme .owl-dots{
	text-align:center;
}
.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791
}
/*owl button < > CENTER to left & right */
.owl-btn-center-lr .owl-nav {
    margin: 0;
}
.owl-btn-center-lr + 
.owl-theme .owl-nav {
    margin: 0;
}
.owl-btn-center-lr .owl-nav .owl-prev,
.owl-btn-center-lr .owl-nav .owl-next {
    position: absolute;
    left: 0;
    top: 50%;
    margin: 0;
	transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}
.owl-btn-center-lr .owl-nav .owl-next {
    left: auto;
    right: 0;
}
.owl-carousel .owl-item img {
    transform-style: inherit;
}
/* Owl Button Style */
.owl-btn-1 .owl-prev, 
.owl-btn-1 .owl-next{
    border-radius: 3px;
    height: 40px;
    line-height: 40px;
    margin: 0 10px !important;
    padding: 0;
    text-align: center;
    width: 40px;
	font-size: 22px;
}
.dots-style-1 .slick-dots{
	margin-top: 30px;
    text-align: center;
    position: unset;

}
.dots-style-1 .slick-dots li button:before{
	content: none;
}
.dots-style-1 .slick-dots li button{
	border-radius: 10px;
	background-color:var(--primary);
    width: 15px;
    height: 15px;
    display: inline-block;
    margin: 0 3px;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    -moz-transform: scale(0.5);
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
	opacity:0.3;
}
.dots-style-1 .slick-dots li.slick-active button{
	transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -moz-transform: scale(1);
	opacity:1;
}
.dots-sc .owl-dots .owl-dot{
	background-color:var(--sc-primary);
}