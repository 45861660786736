.ttr-separator-outer {
    overflow: hidden;
}
.ttr-separator {
    display: inline-block;
    height: 2px;
    width: 80px;
    margin-bottom: 10px;
    position: relative;
}
.ttr-separator.sepimg,
.ttr-separator.sepimg2{
	background-image:url(../../images/separator.png);
	height: 13px;
    background-repeat: no-repeat;
    background-position: center;
    width: 72px;
}
.ttr-separator.sepimg2{
	background-image:url(../../images/separator-black.png);
}
.ttr-separator.style-liner {
    width: 20px;
}
.ttr-separator.style-icon {
    width: 30px;
    height: auto;
    text-align: center;
    font-size: 20px;
}
.ttr-separator[class*="style-"]:after,
.ttr-separator[class*="style-"]:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 40px;
    width: 70px;
    height: 2px;
    background: #eee;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.ttr-separator[class*="style-"]:before {
    left: auto;
    right: 40px;
}
.ttr-separator.bnr-title{
	height:1px;
	width: 155px;
	opacity: 0.5;
}
.ttr-separator.bnr-title:before {
    height: inherit;
    right: -80px;
    width: 25px;
}
.ttr-separator.bnr-title:after {
    height: inherit;
    right: -90px;
    top: 0;
    width: 6px;
}
.ttr-separator.bnr-title:before,
.ttr-separator.bnr-title:after{
	position:absolute;
	content:"";
	background-color:inherit;
}
.ttr-separator.bnr-title i {
    background-color: inherit;
    display: block;
    height: inherit;
    position: absolute;
    right: -50px;
    width: 45px;
}