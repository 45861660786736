/*== >Pricing table  ===*/
.pricingtable-inner {
    text-align: center;
}
.pricingtable-price {
    padding: 20px 20px;
    background-color: #fff;
    border: 1px solid #E9E9E9;
}
.pricingtable-bx {
    color: #282a3c;
    font-family: montserrat;
    font-size: 45px;
    font-weight: 500;
}
.pricingtable-type {
    font-size: 20px;
    text-transform: uppercase;
    font-size: 16px;
}
.pricingtable-type:before {
    content: "/";
    margin-right: 3px;
}
.pricingtable-title {
    padding: 20px;
    text-transform: uppercase;
}
.pricingtable-title * {
    margin: 0;
    color: #fff;
    font-weight: 800;
}
.pricingtable-features {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #E9E9E9;
}
.pricingtable-features li {
    padding: 12px;
    border-bottom: 1px solid #E9E9E9;
}
.pricingtable-features li i {
    margin: 0 3px;
}
.pricingtable-footer {
    margin-top: -1px;
    padding: 20px;
    border: 1px solid #E9E9E9;
}
.pricingtable-highlight.pricingtable-inner .pricingtable-title p{
	background: #020d26;
}
.pricingtable-highlight.pricingtable-inner {
   background:var(--primary);
}
.pricingtable-highlight.pricingtable-inner .pricingtable-price,
.pricingtable-highlight.pricingtable-inner .pricingtable-title{
	border-bottom: 1px solid rgba(255,255,255,0.3);
}
.pricingtable-highlight.pricingtable-inner .btn{
	background: #020d26;
}
.pricingtable-highlight.pricingtable-inner .pricingtable-footer{
	border-top: 1px solid rgba(255,255,255,0.3);
}
.pricingtable-highlight.pricingtable-inner .pricingtable-features li i,
.pricingtable-highlight.pricingtable-inner .pricingtable-features li,
.pricingtable-highlight.pricingtable-inner .pricingtable-type:before,
.pricingtable-highlight.pricingtable-inner .pricingtable-type,
.pricingtable-highlight.pricingtable-inner .pricingtable-bx,
.pricingtable-highlight.pricingtable-inner .priceing-doller,
.pricingtable-highlight.pricingtable-inner .pricingtable-title h2{
	color:#fff;
}






