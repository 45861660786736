.page-banner + .breadcrumb-row {
    background: rgba(255,255,255,0.1);
    padding: 10px 20px;
    border: 1px solid rgba(255,255,255,0.3);
    display: inline-block;
    border-radius: 6px;
}

.breadcrumb-row ul {
    margin: 0;
}
.breadcrumb-row ul li {
    padding: 0;
    margin-right: 3px;
    color: #fff;
	display: inline-block;
}
.breadcrumb-row ul li:after {
    content: "\f105";
    margin-left: 7px;
	font-family: fontawesome;
	opacity:0.5;
	
}
.breadcrumb-row ul li:last-child {
    color: #fff;
}
.breadcrumb-row ul li:last-child:after {
    display: none;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    padding: 10px;
}
.table.borderless > tbody > tr > td,
.table.borderless > tbody > tr > th {
    border: none;
}
.dataTable .sorting_asc .checkbox {
    margin-right: 0;
}